import React from "react";
import BaseLayout from "../components/baseLayout";
import HeroTitle from "../components/heroTitle";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import HeaderHelmet from "../components/headerHelmet";
import { BlogSectionContainer } from "../components/smallsComponents";
import "../style/projectblog.css";

export default function BlogPost({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  const baseUrl = "https://trimweb.it/";
  const title = frontmatter.title;
  const date = frontmatter.date;
  const description = frontmatter.description;

  const url = new URL(frontmatter.path, baseUrl);
  const image = new URL(
    frontmatter.img.childImageSharp.openGraphImg.images.fallback.src,
    baseUrl
  );

  return (
    <BaseLayout>
      <HeaderHelmet
        url={url}
        title={title}
        description={description}
        image={image}
      />

      <div className="relative">
        <GatsbyImage
          style={{ maxHeight: "600pt", width: "100%" }}
          image={frontmatter.img.childImageSharp.contentImg}
          alt={title}
          objectFit={"cover"}
        />
        <div className="photo-credits has-text-weight-bold is-size-7">
          {frontmatter.imgCredits}
        </div>
      </div>

      <HeroTitle title={title} subtitle={date} description={description} />

      <div className="container">
        <BlogSectionContainer>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </BlogSectionContainer>
      </div>
    </BaseLayout>
  );
}

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        date(formatString: "MMMM Do, YYYY")
        title
        description
        imgCredits
        img {
          childImageSharp {
            contentImg: gatsbyImageData(placeholder: BLURRED)
            openGraphImg: gatsbyImageData(
              width: 1200
              height: 630
              formats: [JPG]
            )
          }
        }
      }
    }
  }
`;
